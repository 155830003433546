import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../components/layout';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
	FacebookShareButton,
	TwitterShareButton,
	EmailShareButton,
	LinkedinShareButton
} from 'react-share';
import { Facebook, Twitter, Mail, Linkedin } from 'react-feather';

import ContactForm from '../components/ContactForm';
import { ServingCitiesText } from '../components/ServingCitiesText';
import { RelatedProjects } from '../components/RelatedProjects'

import Breadcrumbs from '../components/breadcrumbs';
import { AreasServed } from '../components/areas-served';

const ContactCard = styled.div`
	top: 30px !important;
	background: rgb(249 249 249);
`;

const Header = styled.div`
	text-align: left;
	margin: 0 auto;
	margin-bottom: 1em;
	border-bottom: 1px solid #eaeaea;
	h1 {
		font-size: calc(1.25vw + 1.25em);
		line-height: 1.15;
		font-weight: 800;
		color: #000;
	}
	border-bottom: 1px solid #f1f1f1;

	background: #f7f7f7;
`;

const ShareThisPost = styled.div`
	p {
		font-weight: bold;
		margin: 0;
	}
	display: flex;
	align-items: center;
	.SocialMediaShareButton {
		display: flex;
		padding: 0 9px;
	}
	svg {
		stroke: none;
		fill: #888;
		cursor: pointer;
	}
	.stroke-white {
		stroke: white;
	}
`;

class BlogTemplate extends Component {
	render() {
		const post = this.props.data.contentfulBlogPost;
		const siteurl = this.props.data.site.siteMetadata.siteUrl;
		const relatedPosts = this.props.data.related;

		return (
			<Layout pageProps={this.props}>
				<div className="bg-white border-b border-gray-100">
					<nav
						className="flex max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 py-3"
						aria-label="Breadcrumb"
					>
						<Breadcrumbs
							links={[
								{ to: '/', label: 'Home' },
								{ to: '/blog/', label: 'Blog' },
								{
									to: this.props.location.pathname,
									label: post.title
								}
							]}
						/>
					</nav>
				</div>
				<SEO
					title={post.metaTitle ? post.metaTitle : post.title}
					description={
						post.metaDescription ? post.metaDescription : post.title
					}
					img={
						post.heroImage
							? post.heroImage.seoImage.images.fallback.src
							: ''
					}
					twitterCardContent={'summary_large_image'}
				/>
				<div className="relative">
					<article itemScope itemType="https://schema.org/Article">
						<div
							itemProp="publisher"
							itemScope
							itemType="https://schema.org/Organization"
							className="hidden"
						>
							<span itemProp="name" className="hidden">
								Bay Area Siding Company
							</span>
							<span
								itemProp="image"
								itemScope
								itemType="http://schema.org/ImageObject"
								className="hidden"
							>
								<img
									src="https://images.ctfassets.net/xa41rilplmsh/5V4Bf6tnTqMOG1SNoiX26D/9d73b7a32fcaca5d3413f37086170d7a/basc-logo.png"
									className="hidden"
									width="262"
									itemProp="url"
								/>
							</span>
							<span
								itemProp="logo"
								itemScope
								itemType="http://schema.org/ImageObject"
								className="hidden"
							>
								<img
									src="https://images.ctfassets.net/xa41rilplmsh/5V4Bf6tnTqMOG1SNoiX26D/9d73b7a32fcaca5d3413f37086170d7a/basc-logo.png"
									className="hidden"
									width="262"
									itemProp="url"
								/>
							</span>
						</div>

						<div className="  max-w-7xl    px-4 md:px-6 flex flex-wrap px-2 md:px-6 mx-auto py-10 ">
							<div className="w-full md:w-2/3 p-0 md:pr-16 relative articleBody w-full  z-1 prose">
								<div
									className="text-center md:text-left blogimgmw w-full  relative"
									style={{ maxWidth: '900px' }}
								>
									<Link
										className="text-brand-500 font-bold mx-auto py-1 rounded text-base  uppercase"
										to={
											'/blog/category/' +
											post.category.slug +
											'/'
										}
										title={
											'View all posts in ' +
											post.category.name
										}
									>
										{post.category.name}
									</Link>

									<h1
										itemProp="name headline"
										className="my-2"
									>
										{post.title}
									</h1>

									<p className="font-semibold  text-base   text-gray-700">
										By{' '}
										<span itemProp="author">
											{post.author.name}
										</span>{' '}
										<time
											itemProp="datePublished"
											className="text-gray-600"
											content={post.microDataPublishDate}
										>
											— {post.publishDate}
										</time>
									</p>

									<div
										className="mx-auto overflow-hidden shadow-lg  rounded-b-2xl relative z-1"
										style={{ marginBottom: '-70px' }}
									>
										<div
											itemProp="image"
											itemScope
											itemType="http://schema.org/ImageObject"
										>
											<img
												src={
													post.heroImage
														? post.heroImage
																.seoImage.images
																.fallback.src
														: ''
												}
												className="hidden"
												width="900"
												height="400"
												itemProp="url"
												alt={post.title}
											/>

											{post.heroImage ? (
												<GatsbyImage
													image={getImage(
														post.heroImage
													)}
													alt={post.title}
													style={{
														height: '400px',
														objectFit: 'cover',
														boxShadow:
															'1px 2px 8px 1px hsla(0,0%,0%,0)'
													}}
													className="blogPostImage rounded-t-2xl"
												/>
											) : null}
										</div>

										<ShareThisPost className="bg-white  rounded-b-2xl px-6 justify-content-end p-2">
											<p className="text-base tracking-widest uppercase">
												Share —{' '}
											</p>
											<FacebookShareButton
												url={
													siteurl +
													'/blog/' +
													post.slug
												}
												quote={post.title}
												hashtag={'#bayareasiding'}
												className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
											>
												<Facebook size={20} />
											</FacebookShareButton>

											<TwitterShareButton
												url={
													siteurl +
													'/blog/' +
													post.slug
												}
												title={post.title}
												hashtags={post.tags}
												className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
											>
												<Twitter size={20} />
											</TwitterShareButton>
											<LinkedinShareButton
												url={
													siteurl +
													'/blog/' +
													post.slug
												}
												title={post.title}
												quote={post.title}
												hashtags={post.tags}
												className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
											>
												<Linkedin size={20} />
											</LinkedinShareButton>
											<EmailShareButton
												subject={post.title}
												url={
													siteurl +
													'/blog/' +
													post.slug
												}
												openWindow={true}
												body={
													'Check out this article: '
												}
												className="bg-white hover:bg-gray-200 cursor-pointer flex h-8 items-center p-1 rounded-full w-8 mr-1"
											>
												<Mail
													size={20}
													className="stroke-white"
												/>
											</EmailShareButton>
										</ShareThisPost>
									</div>
								</div>
								<div
									style={{ marginTop: '110px' }}
									itemProp="articleBody"
									dangerouslySetInnerHTML={{
										__html: post.body.body
									}}
								/>
							</div>

							<div className="w-full md:w-1/3 p-0 md:pl-6">
								<div className="p-6 rounded-2xl z-1 bg-white shadow-lg sticky top-28">
									<h3 className="text-center md:text-left mb-3 font-display text-xl">
										Contact Bay Area Siding Company
									</h3>

									<ContactForm
										showLabels
										showPlaceHolders
										pageLocation={
											this.props.location.href
												? this.props.location.href
												: 'Not provided'
										}
									/>
								</div>
							</div>
						</div>
					</article>
				</div>
				{relatedPosts.edges &&
					relatedPosts.edges &&
					relatedPosts.edges.length > 0 && (
						<div className="max-w-7xl  px-4 md:px-6 mx-auto py-12">
							<h2 className="font-semibold font-display text-lg mb-4">
								More Posts in {post.category.name}
							</h2>
							<div className="grid grid-cols-2 gap-6 lg:grid-cols-3">
								{relatedPosts.edges.map((item, i) => (
									<Link
										key={i}
										to={`/blog/${item.node.slug}`}
										className="bg-white rounded-xl overflow-hidden shadow hover:shadow-lg"
									>
										<GatsbyImage
											image={getImage(
												item.node.heroImage
											)}
											style={{ height: '150px' }}
											className="w-full"
											alt={item.node.title}
										/>
										<div className="p-3 py-4">
											<span className="text-gray-700 text-base">
												{item.node.publishDate}
											</span>
											<span className=" font-semibold leading-tight text-gray-900 block">
												{item.node.title}{' '}
											</span>
										</div>
									</Link>
								))}
							</div>

						</div>
					)}
												<div class="max-w-7xl  px-4 md:px-6 mx-auto py-12"><RelatedProjects data={this.props.data.relatedProjects} /></div>

			</Layout>
			
		);
	}
}

export default BlogTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!, $categoryId: String) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		contentfulBlogPost(slug: { eq: $slug }) {
			id
			title
			slug
			metaTitle
			metaDescription
			metaRobotsContent
			publishDate(formatString: "MMMM Do, YYYY")
			microDataPublishDate: publishDate(formatString: "YYYY-MM-D")
			heroImage {
				gatsbyImageData(width: 1500, quality: 70, placeholder: NONE)
				seoImage: gatsbyImageData(
					width: 1200
					height: 630
					quality: 100
					placeholder: NONE
				)
			}
			author {
				name
				slug
			}
			body {
				body
			}
			category {
				name
				slug
				description
			}
			tags
		}
		relatedProjects: allContentfulProjects(
      limit: 8
    ) {
      edges {
        node {
          id
          title
          slug
          city
          zipCode
          category {
            slug
          }
          image: thumbnail {
            gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
          }
        }
      }
    }
	
		related: allContentfulBlogPost(
			filter: { category: { id: { eq: $categoryId } } }
			sort: { fields: publishDate, order: DESC }
			limit: 6
		) {
			edges {
				node {
					id
					title
					publishDate(formatString: "MMMM Do, YYYY")
					slug
					heroImage {
						gatsbyImageData(
							width: 400
							quality: 70
							placeholder: NONE
						)
					}
					metaDescription
					author {
						name
					}
					category {
						name
					}
				}
			}
		}
		contentfulBlogCategory(id: { eq: $categoryId }) {
			id
			name
		}
	}
`;
